import * as React from "react";
import {withLayout, LayoutProps} from "../components/Layout";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown/with-html";

interface GenericPageProps extends LayoutProps {
  data: {
    page: any;
  };
}

const GenericPage = (props: GenericPageProps) => {
  const {data} = props;
  return (
    <article class="pa3 pa5-ns">
      <h1>{data.page.Title}</h1>
      <div class="f4 measure-wide">
        <ReactMarkdown
          source={data.page.Content}
          escapeHtml={false}
        />
      </div>
    </article>
  );
};

export default withLayout(GenericPage);

export const pageQuery = graphql`
  query GenericPageQuery($id: String) {
    page: strapiPage(id: {eq: $id}) {
      Title
      Content
    }
  }
`;
